import { render, staticRenderFns } from "./HeaderHeading.vue?vue&type=template&id=608234c4&scoped=true"
import script from "./HeaderHeading.vue?vue&type=script&lang=js"
export * from "./HeaderHeading.vue?vue&type=script&lang=js"
import style0 from "./HeaderHeading.vue?vue&type=style&index=0&id=608234c4&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "608234c4",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsNavLabel: require('/src/components/atoms/NavLabel.vue').default,MoleculesVisual: require('/src/components/molecules/Visual.vue').default,AtomsContainer: require('/src/components/atoms/Container.vue').default})
