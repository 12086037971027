import { render, staticRenderFns } from "./StoryCategories.vue?vue&type=template&id=9ce4d010&scoped=true"
import script from "./StoryCategories.vue?vue&type=script&lang=js"
export * from "./StoryCategories.vue?vue&type=script&lang=js"
import style0 from "./StoryCategories.vue?vue&type=style&index=0&id=9ce4d010&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9ce4d010",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MoleculesCategoryNav: require('/src/components/molecules/CategoryNav.vue').default,AtomsContainer: require('/src/components/atoms/Container.vue').default,OrganismsHeader: require('/src/components/organisms/Header.vue').default})
