
import { arrayOf, bool, shape, string } from 'vue-types';
export default {
  inheritAttrs: false,
  props: {
    links: arrayOf(
      shape({
        id: string(),
        label: string(),
        to: string(),
        active: bool().def(false),
      }).loose
    ),
  },
  data() {
    return {
      selectOpen: false,
      invisibleLinks: [],
      buttons: {},
    };
  },
  computed: {
    visibleLinks() {
      return this.links.filter(
        (link) => !this.invisibleLinks.some((iLink) => iLink.id === link.id)
      );
    },
    renderSelect() {
      return this.links.length > 5;
    },
    activeLink() {
      return this.links.find((link) => link.active);
    },
  },
  mounted() {
    this.$nextTick(this.renderLinks);
    window.addEventListener('resize', this.renderLinks);
  },
  destroyed() {
    window.removeEventListener('resize', this.renderLinks);
  },
  methods: {
    renderLinks() {
      this.invisibleLinks = [];

      this.$nextTick(() => {
        if (this.$refs.nav) {
          const navWidth = this.$refs.nav.offsetWidth;

          for (const link of this.links) {
            const el = this.$first(this.$refs[link.id])?.$el;

            // if the link does not fit in the nav
            if (el && el.offsetWidth + el.offsetLeft > navWidth) {
              // add to invisible links
              this.invisibleLinks.push(link);
            }
          }
        }
      });
    },
    toggleSelect() {
      this.selectOpen = !this.selectOpen;
    },
  },
};
