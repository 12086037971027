
import {
  arrayOf,
  bool,
  nullable,
  object,
  oneOf,
  oneOfType,
  shape,
  string,
} from 'vue-types';
import {
  COLOR_SCHEME_DARK,
  COLOR_SCHEME_BLUE,
  COLOR_SCHEME_LIGHT,
  COLOR_SCHEME_WHITE,
  SIZE_MEDIUM,
  SIZE_LARGE,
} from '~/constants/General';
import { imageShape } from '~/constants/PropTypes';

export default {
  inheritAttrs: false,
  props: {
    heading: oneOfType([string(), nullable()]),
    body: oneOfType([string(), nullable()]),
    bodyColor: oneOfType([string(), nullable()]),
    titleSize: oneOf([SIZE_MEDIUM, SIZE_LARGE]).def(SIZE_LARGE),
    navLabels: arrayOf(object()),
    ankeilerVisual: arrayOf(shape(imageShape).loose),
    smallVisual: arrayOf(shape(imageShape).loose),
    colorScheme: oneOf([
      COLOR_SCHEME_DARK,
      COLOR_SCHEME_BLUE,
      COLOR_SCHEME_LIGHT,
      COLOR_SCHEME_WHITE,
    ]).def(COLOR_SCHEME_WHITE),
    roundedDown: bool().def(false),
  },
  data() {
    return {
      COLOR_SCHEME_DARK,
      COLOR_SCHEME_BLUE,
      COLOR_SCHEME_LIGHT,
      COLOR_SCHEME_WHITE,
      SIZE_MEDIUM,
      SIZE_LARGE,
    };
  },
  computed: {
    bgColor() {
      if (this.colorScheme === COLOR_SCHEME_DARK) {
        return 'bg-dark-475';
      }
      if (this.colorScheme === COLOR_SCHEME_BLUE) {
        return 'bg-blue-500';
      }
      if (this.colorScheme === COLOR_SCHEME_LIGHT) {
        return 'bg-blue-50';
      }
      return 'bg-white';
    },
  },
};
