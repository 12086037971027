
import {
  arrayOf,
  nullable,
  number,
  object,
  oneOfType,
  string,
} from 'vue-types';
import breakpointsConfig from '~/constants/Breakpoints';
export default {
  props: {
    title: string(),
    heading: oneOfType([string(), nullable()]),
    subheading: oneOfType([string(), nullable()]),
    totalStoryCount: number().def(0),
    categories: arrayOf(object()),
  },
  data() {
    return {
      sticky: false,
    };
  },
  computed: {
    navLinks() {
      const labels = [
        {
          active: this.$route.name.includes('storyOverview'),
          label: this.$t('latest'),
          id: 'latest',
          to: this.localePath({
            name: 'storyOverview',
          }),
        },
        {
          active: this.$route.name.includes('storyAll'),
          label: this.$t('All'),
          id: 'all',
          to: this.localePath({
            name: 'storyAll',
          }),
        },
      ];

      this.categories.forEach((category) => {
        labels.push({
          active: this.$route.params.slug === category.slug,
          id: category.id,
          label: category.title,
          to: this.$pathFromUrl(category.url),
        });
      });

      return labels;
    },
  },
  mounted() {
    window.addEventListener('scroll', this.scrollHandler);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.scrollHandler);
  },
  methods: {
    scrollHandler() {
      const padding = window.innerWidth >= breakpointsConfig.m ? 78 : 24;
      this.sticky = window.scrollY + padding >= this.$refs.nav.offsetTop;
    },
  },
};
