
import { arrayOf, oneOf, shape, string } from 'vue-types';
import {
  SIZE_SMALL,
  SIZE_MEDIUM,
  COLOR_SCHEME_DARK,
  COLOR_SCHEME_LIGHT,
  COLOR_SCHEME_BLUE,
} from '@/constants/General';

export default {
  inheritAttrs: false,
  props: {
    size: oneOf([SIZE_SMALL, SIZE_MEDIUM]).def(SIZE_MEDIUM),
    colorScheme: oneOf([
      COLOR_SCHEME_DARK,
      COLOR_SCHEME_LIGHT,
      COLOR_SCHEME_BLUE,
    ]).def(COLOR_SCHEME_DARK),
    labels: arrayOf(
      shape({
        label: string(),
        to: string(),
      })
    ).def([]),
  },
  data() {
    return {
      SIZE_MEDIUM,
      COLOR_SCHEME_DARK,
      COLOR_SCHEME_LIGHT,
      COLOR_SCHEME_BLUE,
    };
  },
};
