import { render, staticRenderFns } from "./CategoryNav.vue?vue&type=template&id=1ea2d2ea"
import script from "./CategoryNav.vue?vue&type=script&lang=js"
export * from "./CategoryNav.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsButton: require('/src/components/atoms/Button.vue').default,AtomsIcon: require('/src/components/atoms/Icon.vue').default})
