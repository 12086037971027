
import {
  arrayOf,
  nullable,
  object,
  oneOf,
  oneOfType,
  shape,
  string,
} from 'vue-types';
import {
  COLOR_SCHEME_BLUE,
  COLOR_SCHEME_DARK,
  COLOR_SCHEME_LIGHT,
  COLOR_SCHEME_WHITE,
  SIZE_MEDIUM,
  SIZE_LARGE,
} from '~/constants/General';
import { imageShape } from '~/constants/PropTypes';

export default {
  inheritAttrs: false,
  props: {
    heading: oneOfType([string(), nullable()]),
    body: oneOfType([string(), nullable()]),
    bodyColor: oneOfType([string(), nullable()]),
    titleSize: oneOf([SIZE_MEDIUM, SIZE_LARGE]).def(SIZE_LARGE),
    navLabels: arrayOf(object()),
    smallVisual: arrayOf(shape(imageShape).loose),
    colorScheme: oneOf([
      COLOR_SCHEME_WHITE,
      COLOR_SCHEME_DARK,
      COLOR_SCHEME_BLUE,
      COLOR_SCHEME_LIGHT,
    ]).def(COLOR_SCHEME_WHITE),
  },
  data() {
    return {
      COLOR_SCHEME_WHITE,
      COLOR_SCHEME_DARK,
      COLOR_SCHEME_BLUE,
      COLOR_SCHEME_LIGHT,
      SIZE_MEDIUM,
      SIZE_LARGE,
    };
  },
  computed: {
    navLabelColorScheme() {
      return {
        [COLOR_SCHEME_DARK]: COLOR_SCHEME_BLUE,
        [COLOR_SCHEME_BLUE]: COLOR_SCHEME_DARK,
        [COLOR_SCHEME_WHITE]: COLOR_SCHEME_BLUE,
        [COLOR_SCHEME_LIGHT]: COLOR_SCHEME_BLUE,
      }[this.colorScheme];
    },
  },
};
